@value secondary-font, min-large, main-dark, light-peach from '@/styles/variables.module.css';

.form,
.container {
  position: relative;
  width: 100%;
  height: 100%;

  @media min-large {
    height: 50px;
  }
}

.submit {
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  transition: all 0.1s ease;
  cursor: pointer;
  border: none;
  background: light-peach;
  padding-left: 14px;

  @media min-large {
    right: unset;
    left: 0;
    background: transparent;
    height: 100%;
  }
}

.icon {
  /* optical 0 */
  margin-top: 2px;
}

.input {
  transition: all 0.1s ease;
  border: none;
  background: light-peach;
  padding: 17px 16px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  font-family: secondary-font;
  letter-spacing: 0.01em;

  @media min-large {
    cursor: pointer;
    margin-left: 24px;
    background-color: transparent;
    width: 75px;
    height: 100%;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.input::placeholder {
  color: main-dark;
}

.input:focus {
  outline: none;
  border: none;

  @media min-large {
    cursor: text;
    margin-left: 0;
    background-color: light-peach;
    padding: 20px 16px;
    width: 100%;
    font-size: 14px;
    text-transform: none;
  }
}

.active {
  @media min-large {
    cursor: text;
    margin-left: 0;
    background-color: light-peach;
    padding: 20px 16px;
    width: 100%;
    font-size: 14px;
    text-transform: none;
  }
}

.transformSubmit {
  @media min-large {
    right: 0;
    left: unset;
    background-color: light-peach;
    padding-right: 24px;
    padding-left: 24px;
  }
}
