@value secondary-font, main-dark from '@/styles/variables.module.css';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: main-dark;
  padding: 8px 12px;
  color: #fff;
  font-family: secondary-font;
  text-wrap: nowrap;
  column-gap: 10px;
  cursor: pointer;
  border: none;
  height: 38px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.button.primary {
  background: #fff;
  color: main-dark;
}

.button.secondary {
  background: main-dark;
  color: #fff;
}
